import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue, getParsedQuery } from 'Modules/utility.js';

const formPartsConfig = [
  {
    labelText: '記事カテゴリー名',
    key: 'name',
    type: 'text',
  },
]

const IndexArticleCategories = (props) => {
	const cardTitle = useCardTitle();
  const [articleCategories, setArticleCategories] = useState([]);
  const [query, setQuery] = useState({
    limit: 40,
    page: 1,
    sort: {
      key: 'updated_at',
      order: 'desc',
    },
    to_json_option: {
      methods: ['updated_on'],
    }
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/admin/${cardTitle.name}/article_categories`, query), (response) => {
      setArticleCategories(response.body);
    });
  }, [query, cardTitle.name]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query, page: 1}, event.target.name, event.target.value))
  }, [query, setQuery])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
    api.httpDelete(`/admin/${cardTitle.name}/article_categories/${id}`, (response) => {
      if (response.data.result) {
        const newArticleCategories = [];
        const flashMessages = [];
        for (let i = 0; i < articleCategories.length; i++) {
          if (articleCategories[i].id !== id) {
            newArticleCategories.push(articleCategories[i]);
          } else {
            flashMessages.push(`id:${articleCategories[i].id} ${articleCategories[i].name}の削除に成功しました。`);
          }
        }
        localStorage.setItem('flashMessages', flashMessages);
        setArticleCategories([...newArticleCategories]);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        setArticleCategories([...articleCategories]);
      }
    });
	}, [cardTitle, articleCategories])

  return (
    <IndexTemplate
      pageTitle={`${cardTitle.name}:記事カテゴリー一覧`}
      tableHeaders={['id', '記事カテゴリ名', '更新日', '編集', '削除']}
      displayRecordKeys={['id', 'name', 'updated_on']}
      records={articleCategories}
      formPartsConfig={formPartsConfig}
      query={query}
      setQuery={setQuery}
      handleInputValue={handleInputValue}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexArticleCategories