import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue } from 'Modules/utility.js';

const formPartsConfig = [
  {
    labelText: 'パック名',
    key: 'name',
    type: 'text',
  },
  {
    labelText: 'パックコード',
    key: 'code',
    type: 'text',
  },
  {
    labelText: 'パックコード(おちゃ表記)',
    key: 'ocha_pack_code',
    type: 'text',
  },
]

const IndexPacks = (props) => {
	const cardTitle = useCardTitle();
  const [packs, setPacks] = useState([]);
  const [query, setQuery] = useState({
    is_open: [true, false],
    limit: 40,
    page: 1,
    sort: {
      key: 'released_at',
      order: 'desc',
    },
    to_json_option: {
      methods: ['image_source'],
    }
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/packs`, query), (response) => {
      setPacks(response.body.records);
    });
  }, [query, cardTitle.name]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query, page: 1}, event.target.name, event.target.value))
  }, [query, setQuery])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
    api.httpDelete(`/admin/${cardTitle.name}/packs/${id}`, (response) => {
      if (response.data.result) {
        const newPacks = [];
        const flashMessages = [];
        for (let i = 0; i < packs.length; i++) {
          if (packs[i].id !== id) {
            newPacks.push(packs[i]);
          } else {
            flashMessages.push(`id:${packs[i].id} ${packs[i].name}の削除に成功しました。`);
          }
        }
        localStorage.setItem('flashMessages', flashMessages);
        setPacks([...newPacks]);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        setPacks([...packs]);
      }
    });
	}, [packs])

  return (
    <IndexTemplate
      pageTitle={`${cardTitle.name}:パック一覧`}
      tableHeaders={['id', 'パック名', 'パックコード', 'パックコード(整形)', '特記事項', 'パックコード(おちゃ)', '画像', 'カテゴリ', '発売日', '編集', '削除']}
      displayRecordKeys={['id', 'name', 'code', 'downcase_code', 'special_note', 'ocha_pack_code', 'image_source', 'category', 'released_at']}
      records={packs}
      formPartsConfig={formPartsConfig}
      query={query}
      setQuery={setQuery}
      handleInputValue={handleInputValue}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexPacks