import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue } from 'Modules/utility.js';
import articleCategoryConfig from 'Modules/ModelConfigs/Pokemon/ArticleCategory.js'

const getFormPartsConfig = (categoryNames) => {
  return([
    {
      labelText: '記事タイトル',
      key: 'title',
      type: 'text',
    },
    {
      labelText: '執筆者',
      key: 'author',
      type: 'text',
    },
    {
      labelText: '記事カテゴリ',
      key: 'category.name',
      type: 'checkboxList',
      displayOptions: categoryNames,
      list: categoryNames,
    },
  ]);
}

const IndexArticles = (props) => {
	const cardTitle = useCardTitle();
  const [articles, setArticles] = useState([]);
  const [formPartsConfig, setFormPartsConfig] = useState(null);
  const [query, setQuery] = useState({
    is_open: [true, false],
    limit: 40,
    page: 1,
    sort: {
      key: 'created_at',
      order: 'desc',
    },
    category: {
      name: [],
    },
    associations: 'category',
    to_json_option: {
      methods: ['created_on', 'image_source'],
      include: 'category',
      except: 'body',
    }
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/article_categories`, {to_json_option: {only: ['id', 'name']}}), (response) => {
      const categoryNames = response.body.map(category => category.name)
      setFormPartsConfig(getFormPartsConfig(categoryNames))

      setQuery({...query, category: {name: categoryNames.filter((name) => {
        return(!articleCategoryConfig.autoGeneratedArticleCategoryNames.includes(name));
      })}})
    });
  }, [cardTitle.name])

  useEffect(() => {
    api.httpGet(api.addQuery(`/admin/${cardTitle.name}/articles`, query), (response) => {
      setArticles(response.body);
    });
  }, [query, cardTitle.name]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query, page: 1}, event.target.name, event.target.value))
  }, [query, setQuery])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
    api.httpDelete(`/admin/${cardTitle.name}/articles/${id}`, (response) => {
      if (response.data.result) {
        const newArticles = [];
        const flashMessages = [];
        for (let i = 0; i < articles.length; i++) {
          if (articles[i].id !== id) {
            newArticles.push(articles[i]);
          } else {
            flashMessages.push(`id:${articles[i].id} ${articles[i].title}の削除に成功しました。`);
          }
        }
        localStorage.setItem('flashMessages', flashMessages);
        setArticles([...newArticles]);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        setArticles([...articles]);
      }
    });
	}, [cardTitle, articles])

  if (!formPartsConfig) {
    return(null);
  }

  return (
    <IndexTemplate
      pageTitle={`${cardTitle.name}:記事一覧`}
      tableHeaders={['id', 'カテゴリ', '記事タイトル', 'サムネ', '公開状態', '作成日', '編集', '削除']}
      displayRecordKeys={['id', 'category.name', 'title', 'image_source', 'is_open', 'created_on']}
      records={articles}
      formPartsConfig={formPartsConfig}
      query={query}
      setQuery={setQuery}
      handleInputValue={handleInputValue}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexArticles