import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import { useLocation } from 'react-router-dom';

const formPartsConfig = [
  {
    key: 'name',
    type: 'text',
  },
]

const IndexCustomFiles = (props) => {
  const defaultQuery = {
    limit: 40,
    sort: {
      key: 'created_at',
      order: 'DESC',
    },
  };
  const [customFiles, setCustomFiles] = useState([]);
  const [query, setQuery] = useState(defaultQuery);
  const location = useLocation();
  useEffect(() => {
    api.httpGet(api.addQuery(`/admin/custom_files`, defaultQuery), (response) => {
      const records = response.map((record) => {
        const rawKey = record.file_key;
        record.file_key = (
          <span
            style={{ cursor: 'pointer', color: '#007bff', textDecoration: 'underline' }}
            onClick={() => {
              navigator.clipboard.writeText(rawKey).then(() => {
                alert('ファイルパスをコピーしました');
              });
            }}
          >
            {rawKey}
          </span>
        );
        return record;
      });
      setCustomFiles(records);
    });
  }, [location.key]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query}, event.target.name, event.target.value))
  }, [query, setQuery])

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    api.httpGet(api.addQuery(`/admin/custom_files`, query), (response) => {
      setCustomFiles(Array.isArray(response) ? response : []);
    });
  }, [query, setCustomFiles])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
		api.deleteToDestroy(
      props,
      event,
      `/admin/custom_files?key=${encodeURIComponent(id)}`,
      '削除に成功しました。',
      `/custom_files`,
      '削除に失敗しました。',
      `/custom_files`,
    )
	}, [props])

  return (
    <IndexTemplate
      pageTitle={`ファイル一覧`}
      tableHeaders={['name', 'ファイルパス', '作成日', '削除']}
      displayRecordKeys={['name', 'file_key', 'created_on']}
      records={customFiles}
      formPartsConfig={formPartsConfig}
      query={query}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexCustomFiles