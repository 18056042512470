import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import queryParser from 'Modules/queryParser.js';

export const useUrl = () => {
  const location = useLocation();

  const query = useMemo(() => {
    return queryParser.getParsedQuery(location.search);
  }, [location.search]);

  const path = useMemo(() => {
    let pathName = location.pathname;
    if (pathName.endsWith('/')) {
      pathName = pathName.slice(0, -1);
    }
    return pathName;
  }, [location.pathname]);

  const lastPath = useMemo(() => {
    const splited = location.pathname.split('/');
    return splited[splited.length - 1];
  }, [location.pathname]);

  return {
    currentHref: window.location.href,
    currentPath: path,
    query: query,
    lastPath: lastPath,
  };
}

export default useUrl;