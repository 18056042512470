import React, { useState, useEffect } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';

const formPartsConfig = [
  {
    labelText: 'ファイル名',
    key: 'name',
    type: 'text',
  },
  {
    labelText: 'ファイル',
    key: 'file_key',
    type: 'file',
  },
]

const NewCustomFile = (props) => {
  const [customFile, setCustomFile] = useState({
    id: '',
    name: '',
    file_key: '',
    preview_source: '',
    created_at: '',
    updated_at: '',
  });

  const handleInputValue = (event) => {
    let value;
    if (event.target.type === 'file') {
      value = event.target.files[0]
    } else {
      value = event.target.value
    }
    setCustomFile(setNestedJsonValue({...customFile}, event.target.name, value));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("custom_file", JSON.stringify(customFile));
    data.append("file", customFile.file_key);
    api.postToCreate(
      props,
      event,
      `/admin/custom_files`,
      data,
      'ファイルの作成に成功しました。',
      `/custom_files`,
      'ファイルの作成に失敗しました。',
      `/custom_files/new`,
    );
  }

  return(
    <DefaultFormTemplate
      pageTitle={`ファイル:新規作成`}
      record={customFile}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default NewCustomFile