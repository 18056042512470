import React, { useState } from "react";
import styled from "styled-components";
import { ToolButtonWithTips } from 'Components/Atoms/Buttons/ToolButton';

const ToolButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px; /* ボタン間のスペース */
`;

const generateToc = (body) => {
  // 正規表現で<見出し>と<小見出し>を抽出
  const headingRegex = /<(見出し|小見出し)>{(.*?)}/g;
  const matches = [...body.matchAll(headingRegex)];

  // 目次としてフォーマット
  return matches
    .map((match) => `<${match[1]}>{${match[2]}}`) // <見出し>{内容} or <小見出し>{内容}
    .join("\n") + "\n"; // 各行を改行で区切る
};

const handleToolbarAction = (action, textarea, article, setArticle) => {

  if (!textarea) return;

  const start = textarea.selectionStart;
  const end = textarea.selectionEnd;
  const selectedText = article.body.substring(start, end);
  const scrollTop = textarea.scrollTop;

  let modifiedText = article.body;
  let newCursorStart = start;
  let newCursorEnd = end;

  if (action === "太字") {
    modifiedText =
      article.body.substring(0, start) +
      `<太字>{${selectedText}}` +
      article.body.substring(end);
      newCursorStart = start + 5;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "デカ文字") {
    modifiedText =
      article.body.substring(0, start) +
      `<デカ文字>{${selectedText}}` +
      article.body.substring(end);
      newCursorStart = start + 7;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "赤文字") {
    modifiedText =
      article.body.substring(0, start) +
      `<赤文字>{${selectedText}}` +
      article.body.substring(end);
      newCursorStart = start + 6;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "青文字") {
    modifiedText =
      article.body.substring(0, start) +
      `<青文字>{${selectedText}}` +
      article.body.substring(end);
      newCursorStart = start + 6;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "白文字") {
    modifiedText =
      article.body.substring(0, start) +
      `<白文字>{${selectedText}}` +
      article.body.substring(end);
      newCursorStart = start + 6;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "背景赤") {
    modifiedText =
      article.body.substring(0, start) +
      `<背景赤>{${selectedText}}` +
      article.body.substring(end);
      newCursorStart = start + 6;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "背景青") {
    modifiedText =
      article.body.substring(0, start) +
      `<背景青>{${selectedText}}` +
      article.body.substring(end);
      newCursorStart = start + 6;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "右寄せ") {
    modifiedText =
      article.body.substring(0, start) +
      `<右寄せ>{${selectedText}}` +
      article.body.substring(end);
      newCursorStart = start + 6;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "真ん中寄せ") {
    modifiedText =
      article.body.substring(0, start) +
      `<真ん中寄せ>{${selectedText}}` +
      article.body.substring(end);
      newCursorStart = start + 8;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "見出し") {
    modifiedText =
      article.body.substring(0, start) +
      `<見出し>{${selectedText}}` +
      article.body.substring(end);
      newCursorStart = start + 6;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "小見出し") {
    modifiedText =
      article.body.substring(0, start) +
      `<小見出し>{${selectedText}}` +
      article.body.substring(end);
      newCursorStart = start + 7;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "引用") {
    modifiedText =
      article.body.substring(0, start) +
      `<引用>{${selectedText}}` +
      article.body.substring(end);
      newCursorStart = start + 5;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "文字リンク") {
    modifiedText =
      article.body.substring(0, start) +
      `<文字リンク url="">{${selectedText}}` +
      article.body.substring(end);
      newCursorStart = start + 12;
      newCursorEnd = start + 12;
  } else if (action === "画像(大)") {
    modifiedText =
      article.body.substring(0, start) +
      `<画像 url="" image_url="" size="大" 画像の説明="">{}` +
      article.body.substring(end);
      newCursorStart = start + 9;
      newCursorEnd = start + 9;
  } else if (action === "画像(中)") {
    modifiedText =
      article.body.substring(0, start) +
      `<画像 url="" image_url="" size="中" 画像の説明="">{}` +
      article.body.substring(end);
      newCursorStart = start + 9;
      newCursorEnd = start + 9;
  } else if (action === "画像(小)") {
    modifiedText =
      article.body.substring(0, start) +
      `<画像 url="" image_url="" size="小" 画像の説明="">{}` +
      article.body.substring(end);
      newCursorStart = start + 9;
      newCursorEnd = start + 9;
  } else if (action === "手動カード画像") {
    modifiedText =
    article.body.substring(0, start) +
    `<$カード名$画像id$>` +
    article.body.substring(end);
    newCursorStart = start + 20;
    newCursorEnd = start + 20;
  } else if (action === "目次") {
    const tocContent = generateToc(article.body);
    modifiedText =
      article.body.substring(0, start) +
      `---目次Start---\n${tocContent}---目次End---\n` +
      article.body.substring(end);
  } else if (action === "レシピ") {
    modifiedText =
      article.body.substring(0, start) +
      `<レシピ デッキID="">{}` +
      article.body.substring(end);
      newCursorStart = start + 12;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "手動カードリンク") {
    modifiedText =
      article.body.substring(0, start) +
      `<カードリンク カードID="">{${selectedText}}` +
      article.body.substring(end);
      newCursorStart = start + 15;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "カード画像") {
    modifiedText =
      article.body.substring(0, start) +
      `<カード画像 カードID="">{}` +
      article.body.substring(end);
      newCursorStart = start + 14;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "埋め込みurl") {
    modifiedText =
      article.body.substring(0, start) +
      `<埋め込みurl url="">{}` +
      article.body.substring(end);
      newCursorStart = start + 14;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "記事埋め込み") {
    modifiedText =
      article.body.substring(0, start) +
      `<記事埋め込み url="">{}` +
      article.body.substring(end);
      newCursorStart = start + 13;
      newCursorEnd = newCursorStart + selectedText.length;
  } else if (action === "テーブル") {
    modifiedText =
      article.body.substring(0, start) +
      `---テーブル開始---\n|ヘッダー1|ヘッダー2|ヘッダー3|ヘッダー4|\n|データ1|データ2|データ3|データ4|\n|データ1|データ2|データ3|データ4|\n|データ1|データ2|データ3|データ4|\n---テーブル終了---\n` +
      article.body.substring(end);
  } else if (action === "自動カードリンク") {
    const cardRegex = /(?<!<カード文字リンク カードID="[^"]*">{)《([^@》]+)(?:@(\d+))?》(?!})/g;
    modifiedText = article.body.replace(cardRegex, (match, cardName, cardId) => {
      console.log(cardName, cardId);
      return `<カード文字リンク カードID="${cardId || ""}">{《${cardName}》}`;
    });
  } else if (action === "自動カードリンク解除") {
    const cardLinkRegex = /<カード文字リンク カードID="([^"]*)">{《([^》]+)》}/g;
    modifiedText = article.body.replace(cardLinkRegex, (match, cardId, cardName) => {
      return cardId ? `《${cardName}@${cardId}》` : `《${cardName}》`;
    });
  }

  setArticle((prev) => ({ ...prev, body: modifiedText }));

  setTimeout(() => {
    textarea.focus();
    textarea.setSelectionRange(newCursorStart, newCursorEnd);
    textarea.scrollTop = scrollTop;
  }, 0);
};

export const ToolButtons =({textarea, article, setArticle}) => {
  const [visibleTipId, setVisibleTipId] = useState(null);
  const handleToggleTips = (tipId) => {
    setVisibleTipId((prev) => (prev === tipId ? null : tipId)); // 同じIDなら非表示、違うIDなら表示
  };
  return (
    <ToolButtonContainer>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("太字", textarea, article, setArticle)}
        tipId="bold"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={"【太字】\n選択したテキストを太字にします。\n例:ここが<太字>{xxxxx}になります。\nこの例だと「xxxxx」が太文字になります\n他のスタイルと併用可能です。\n<赤文字>{<太字>{xxxxx}}とすると「xxxxx」が赤文字かつ太字になります。"}
      >
        太字
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("デカ文字", textarea, article, setArticle)}
        tipId="big"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={"【デカ文字】\n選択したテキストをデカ文字にします。\n例:ここが<デカ文字>{xxxxx}になります。\nこの例だと「xxxxx」がデカ文字になります\n他のスタイルと併用可能です。\n<デカ文字>{<太字>{xxxxx}}とすると「xxxxx」がデカ文字かつ太字になります。"}
      >
        デカ文字
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("赤文字", textarea, article, setArticle)}
        tipId="red-text"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={"【赤文字】\n選択したテキストを赤文字にします。\n例:ここが<赤文字>{xxxxx}になります。\nこの例だと「xxxxx」が赤文字になります\n他のスタイルと併用可能です。\n<赤文字>{<太字>{xxxxx}}とすると「xxxxx」が赤文字かつ太字になります。"}
      >
        赤文字
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("青文字", textarea, article, setArticle)}
        tipId="blue-text"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={"【青文字】\n選択したテキストを青文字にします。\n例:ここが<青文字>{xxxxx}になります。\nこの例だと「xxxxx」が青文字になります\n他のスタイルと併用可能です。\n<青文字>{<太字>{xxxxx}}とすると「xxxxx」が青文字かつ太字になります。"}
      >
        青文字
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("白文字", textarea, article, setArticle)}
        tipId="white-text"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={"【白文字】\n選択したテキストを白文字にします。\n例:ここが<白文字>{xxxxx}になります。\nこの例だと「xxxxx」が白文字になります\n他のスタイルと併用可能です。\n<白文字>{<太字>{xxxxx}}とすると「xxxxx」が白文字かつ太字になります。"}
      >
        白文字
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("背景赤", textarea, article, setArticle)}
        tipId="red-background"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={"【背景赤】\n選択したテキストの背景が赤になります。\n例:ここが<背景赤>{xxxxx}になります。\nこの例だと「xxxxx」が赤い背景になります\n他のスタイルと併用可能です。\n<背景赤>{<太字>{xxxxx}}とすると「xxxxx」が赤背景かつ太字になります。"}
      >
        背景赤
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("背景青", textarea, article, setArticle)}
        tipId="blue-background"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={"【背景青】\n選択したテキストの背景が青になります。\n例:ここが<背景青>{xxxxx}になります。\nこの例だと「xxxxx」が青い背景になります\n他のスタイルと併用可能です。\n<背景青>{<太字>{xxxxx}}とすると「xxxxx」が青背景かつ太字になります。"}
      >
        背景青
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("右寄せ", textarea, article, setArticle)}
        tipId="right-align"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={"【右寄せ】\n選択したテキストが右寄せになります。\n例:<右寄せ>{右寄せの文章です}\nこの例だと「右寄せの文章です」が右寄せになります\n他のスタイルと併用可能です。\n<右寄せ>{<太字>{xxxxx}}とすると「xxxxx」が右寄せかつ太字になります。"}
      >
        右寄せ
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("真ん中寄せ", textarea, article, setArticle)}
        tipId="center-align"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={"【真ん中寄せ】\n選択したテキストが中央寄せになります。\n例:<真ん中>{真ん中寄せの文章です}\nこの例だと「真ん中寄せの文章です」が真ん中寄せになります\n他のスタイルと併用可能です。\n<真ん中>{<太字>{xxxxx}}とすると「xxxxx」が中央寄せかつ太字になります。"}
      >
        真ん中寄せ
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("見出し", textarea, article, setArticle)}
        tipId="headline"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={"【見出し】\n選択したテキストが見出しになります。\n例:<見出し>{見出しコンテンツ}\nこの例だと「見出しコンテンツ」が見出しになります"}
      >
        見出し
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("小見出し", textarea, article, setArticle)}
        tipId="sub-headline"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={"【小見出し】\n選択したテキストが小見出しになります。\n例:<小見出し>{小見出しコンテンツ}\nこの例だと「小見出しコンテンツ」が見出しになります"}
      >
        小見出し
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("引用", textarea, article, setArticle)}
        tipId="quote"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={"【引用】\n選択したテキストが引用になります。\n例:<引用>{引用コンテンツ}\nこの例だと「引用コンテンツ」が引用になります\n他のスタイルと併用可能です。\n<引用>{<太字>{xxxxx}}とすると「xxxxx」が引用かつ太字になります。"}
      >
        引用
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("文字リンク", textarea, article, setArticle)}
        tipId="text-link"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={'【文字リンク】\n選択したテキストが引用になります。\n例:<文字リンク url="https://example.com">{こちら}\nこの例だと「こちら」がhttp://example.comのリンクになります\n他のスタイルと併用可能です。\n<文字リンク url="https://example.com">{<太字>{xxxxx}}とすると「xxxxx」がリンクかつ太字になります。'}
      >
        文字リンク
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("画像(大)", textarea, article, setArticle)}
        tipId="big-image"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={'【画像(大)】\n大きい画像を挿入します。\n例:<画像 url="https://hogefuga..." image_url="https://example..." size="大" 画像の説明="画像の説明文">{}\nこの例だとhttps://example...の画像を表示し、画像クリックでhttps://hogefuga...にリンクします。(urlは必須ではないのでリンク先がない場合はそのままurl=""で大丈夫です)\n画像の説明は表示には関係ないですがシステム的に必要なので何かしらいれてください。'}  
      >
        画像(大)
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("画像(中)", textarea, article, setArticle)}
        tipId="medium-image"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={'【画像(中)】\n中くらいの画像を挿入します。\n例:<画像 url="https://hogefuga..." image_url="https://example..." size="中" 画像の説明="画像の説明文">{}\nこの例だとhttps://example...の画像を表示し、画像クリックでhttps://hogefuga...にリンクします。(urlは必須ではないのでリンク先がない場合はそのままurl=""で大丈夫です)\n画像の説明は表示には関係ないですがシステム的に必要なので何かしらいれてください'}
      >
        画像(中)
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("画像(小)", textarea, article, setArticle)}
        tipId="small-image"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={'【画像(小)】\n小さい画像を挿入します。\n例:<画像 url="https://hogefuga..." image_url="https://example..." size="小" 画像の説明="画像の説明文">{}\nこの例だとhttps://example...の画像を表示し、画像クリックでhttps://hogefuga...にリンクします。(urlは必須ではないのでリンク先がない場合はそのままurl=""で大丈夫です)\n画像の説明は表示には関係ないですがシステム的に必要なので何かしらいれてください'}
      >
        画像(小)
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("手動カード画像", textarea, article, setArticle)}
        tipId="manual-card-image"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={'【手動カード画像】\nカード画像に適したサイズの画像を挿入します。\n例:<$カード名$画像id$>\nこの例だと画像idに紐付いた画像を表示し、画像クリックで通販のカード名で検索したページにリンクします。\n遷移先に型番を追加したい場合は<$カード名$画像id$型番$>としてください。\nまた、画像id部分にurlを直接入れた場合はそのurlの画像が表示されます。'}
      >
        手動カード画像
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("目次", textarea, article, setArticle)}
        tipId="table-of-contents"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={'【目次】\n目次を挿入します。\n「見出し」と「小見出し」を元に目次を自動で作成します。\n「見出し」「小見出し」を作り終わったら挿入したい箇所にカーソルを指定して目次ボタンを押してください。'}
      >
        目次
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("レシピ", textarea, article, setArticle)}
        tipId="recipe"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={'【レシピ】\nデッキレシピを挿入します。\n<レシピ デッキID="2">{}\nこの例だとデッキIDが「2」のデッキレシピが表示されます'}
      >
        レシピ
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("カード画像", textarea, article, setArticle)}
        tipId="auto-card-image"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={'【カード画像】\nカード画像を自動で生成します。\n<カード画像 カードID="4">{}\nこの例だとカードIDが「4」のカード画像が表示されます。'}
      >
        カード画像
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("埋め込みurl", textarea, article, setArticle)}
        tipId="embed-url"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={'【埋め込みurl】\nurlを元に埋め込みます(YoutubeとTwitchとnoteとX(旧Twitter))\n<埋め込みurl url="https://example.com">{}\nこの例だとhttps://example.comの埋め込みが表示されます'}
      >
        埋め込みurl
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("記事埋め込み", textarea, article, setArticle)}
        tipId="embed-article"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={'【記事埋め込み】\nラッシュメディアの記事を埋め込みます。\n<記事埋め込み url="https://cardrush.media/card_title/articles/:id">{}のようにurlにラッシュメディアの記事のURLを入れてください。'}
      >
        記事埋め込み
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("テーブル", textarea, article, setArticle)}
        tipId="table"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={'【テーブル】\nテーブルを挿入します。\nテーブルを挿入したらヘッダーや要素を自由に編集してください。'}
      >
        テーブル
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("自動カードリンク", textarea, article, setArticle)}
        tipId="auto-card-link"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={'【自動カードリンク】\n《XXXXXXX@id》に対して<カード文字リンク カードID="id">{XXXXXXX}と変換します。\n内部的にカード名で検索してカードIDを取得するので必須ではありませんが、カード名では1枚に特定できない場合はカードIDも必要です。\n《YYYYYYY》に対しては<カード文字リンク カードID="">{YYYYYYY}と変換します。idがなくても特定できるカード名であればそのままで大丈夫です。\n\nラッシュメディアがリリースされてないタイトルは該当文字列で通販ページに飛ぶリンクとなります。'}
      >
        自動カードリンク
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("自動カードリンク解除", textarea, article, setArticle)}
        tipId="auto-card-link-canceling"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={'【自動カードリンク】\n<カード文字リンク カードID="id">{XXXXXXX}に対して《XXXXXXX@id》と変換します。\n<カード文字リンク カードID="">{YYYYYYY}に対しては《YYYYYYY》と変換します。'}
      >
        自動カードリンク解除
      </ToolButtonWithTips>
      <ToolButtonWithTips
        onClick={() => handleToolbarAction("手動カードリンク", textarea, article, setArticle)}
        tipId="card-link"
        visibleTipId={visibleTipId}
        handleToggleTips={handleToggleTips}
        tipsText={'【手動カードリンク】\nカードリンクを生成します。\n<カードリンク カードID="4">{カード名}\nこの例だとカードIDが「4」のカードリンクが《カード名》として生成されます。\n\nラッシュメディアがリリースされてないタイトルは該当文字列で通販ページに飛ぶリンクとなります。\n<カードリンク カードID="">{カード名}とすることで通販のカード名で検索したページに遷移します。'}
      >
        手動カードリンク
      </ToolButtonWithTips>
    </ToolButtonContainer>
  )
}
