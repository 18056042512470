import useCardTitle from 'Modules/useCardTitle';
import { ErrorModal } from 'Components/Molecules/ErrorModal';
import api from 'Modules/api.js';
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { ToolButtons } from 'Components/Molecules/ToolButtons';
import { ArticleTags } from 'Components/Molecules/ArticleTags';
import enviroment from 'Modules/enviroment.js';

// Styled Components
const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

const FormWrapper = styled.form`
  width: 70%;
`;

const ToolbarWrapper = styled.div`
  width: 25%;
  padding: 10px;
  border-left: 1px solid #ccc;
  background-color: #f2f2f2;
  position: sticky; /* 固定する */
  top: 0; /* スクロール時に上端に固定 */
  height: calc(100vh - 20px); /* ビューポートの高さに合わせる */
  overflow-y: auto; /* 縦スクロールを有効にする */
  scrollbar-width: thin; /* スクロールバーの幅を調整 (モダンブラウザ) */
  scrollbar-color: #ccc #f2f2f2; /* スクロールバーの色 (モダンブラウザ) */

  /* スクロールバーのスタイルをカスタマイズ（Webkit系ブラウザ用） */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
`;

const Heading = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const BackLink = styled.a`
  display: block;
  margin-bottom: 20px;
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  min-height: 800px;
  resize: vertical;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const FileInput = styled.input`
  width: 100%;
`;

const Button = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const FilePreview = styled.p`
  font-size: 14px;
  color: #555;
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 15px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
`;


const ToolbarHeading = styled.h3`
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
`;

const NewArticleForm = () => {
  const cardTitle = useCardTitle();
  const [article, setArticle] = useState({
    title: "",
    [`${cardTitle.name}_article_category_id`]: "",
    is_open: false,
    image_key: '',
    preview_source: "",
    body: "",
    author_id: "",
    reccomend_text: "",
    picked_up_card_ids: [],
    tag_relations_attributes: [],
    reccomend_url: "",
  });

  const textareaRef = useRef(null);
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [articleTags, setArticleTags] = useState([]);

  const [errorMessages, setErrorMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setErrorMessages([]);
  };

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/article_categories`, {limit: 300}), (response) => {
      const newCategories = [...response.body];
      newCategories.unshift({id: '', name: '未指定'});
      setCategories(newCategories);
    });

    api.httpGet(api.addQuery(`/admin/authors`, {limit: 300}), (response) => {
      const newAuthors = [...response.body];
      newAuthors.unshift({id: '', name: '未指定'});
      setAuthors(newAuthors);
    });

    api.httpGet(api.addQuery(`/${cardTitle.name}/article_tags`, {limit: 300}), (response) => {
      setArticleTags(response.body);
    });

    // api.httpGet(`/${cardTitle.name}/article_categories/archetype_summary`, (response) => {
    //   setArchetypeSummaryArticleCategory(response.body);
    // })

  }, [])

  // サムネイルファイル変更
  const handleFileChange = (e) => {
    setArticle((prev) => ({ ...prev, image_key: e.target.files[0] }));
  };

  // 注目カード変更
  const handleCardChange = (index, value) => {
    setArticle((prev) => {
      const updatedCards = [...prev.picked_up_card_ids];
      updatedCards[index] = value;
      return { ...prev, picked_up_card_ids: updatedCards };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticle((prev) => ({ ...prev, [name]: value }));
  };

  const handlePreview = (props) => {
    const onClick = (event) => {
      event.preventDefault();
      api.httpPost(`/admin/${props.cardTitle.name}/articles/generate_preview_file`, {article: props.article}, (response) => {
        if (response.data.result == false) {
          setErrorMessages([response.data.error_message]);
          setIsModalOpen(true);
        }
        else if (response.status == 200) {
          window.open(`${enviroment.userFrontOrigin}/${props.cardTitle.name}/articles/preview?id=${response.data.id}`);
        }
      })
    }
    return(
      <button style={{marginBottom: '20px'}} onClick={onClick} >プレビュー</button>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("article", JSON.stringify(article));
    data.append("file", article.image_key);

    api.httpPost(`/admin/${cardTitle.name}/articles`, data, (response) => {
      if (response?.data?.result) {
        localStorage.setItem("flashMessages", "作成に成功しました。");
        window.location.href = "/articles";
      } else {
        const errors = response?.data?.body || ["作成に失敗しました。"];
        setErrorMessages(errors);
        setIsModalOpen(true);
      }
    });
  };

  return (
    <FormContainer>
      <FormWrapper onSubmit={handleSubmit}>
        <Heading>{`【${cardTitle.name}】記事:新規作成`}</Heading>
        <BackLink href="/articles">一覧画面へ戻る</BackLink>
        {/* モーダル */}
        {isModalOpen && (
          <ErrorModal
            errorMessages={errorMessages}
            onClose={handleCloseModal}
          />
        )}

        {/* 公開状態 */}
        <FormGroup>
          <Label>公開状態</Label>
          <RadioGroup>
            <RadioLabel>
              <input
                type="radio"
                name="is_open"
                value={true}
                checked={article.is_open === true}
                onChange={(e) => setArticle((prev) => ({ ...prev, is_open: JSON.parse(e.target.value) }))}
              />
              公開
            </RadioLabel>
            <RadioLabel>
              <input
                type="radio"
                name="is_open"
                value={false}
                checked={article.is_open === false}
                onChange={(e) => setArticle((prev) => ({ ...prev, is_open: JSON.parse(e.target.value) }))}
              />
              非公開
            </RadioLabel>
          </RadioGroup>
        </FormGroup>


        {/* 記事タイトル */}
        <FormGroup>
          <Label>記事タイトル</Label>
          <Input
            type="text"
            name="title"
            value={article.title}
            onChange={handleChange}
          />
        </FormGroup>

        {/* 記事カテゴリ */}
        <FormGroup>
          <Label>記事カテゴリ</Label>
          <Select
            name={`${cardTitle.name}_article_category_id`}
            value={article[`${cardTitle.name}_article_category_id`]}
            onChange={handleChange}
          >
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Select>
        </FormGroup>

        {/* ライター名 */}
        <FormGroup>
          <Label>ライター名</Label>
          <Select
            name="author_id"
            value={article.author}
            onChange={handleChange}
          >
            {authors.map((author) => (
              <option key={author.id} value={author.id}>
                {author.name}
              </option>
            ))}
          </Select>
        </FormGroup>

        {/* サムネ画像 */}
        <FormGroup>
          <Label>サムネ画像</Label>
          <FileInput type="file" onChange={handleFileChange} />
          {article.thumbnail && (
            <FilePreview>選択されたファイル: {article.thumbnail.name}</FilePreview>
          )}
        </FormGroup>

        {/* 注目カード */}
        {[0, 1, 2, 3].map((index) => (
          <FormGroup key={index}>
            <Label>注目カード{index + 1}</Label>
            <Input
              type="text"
              value={article.picked_up_card_ids[index]}
              onChange={(e) => handleCardChange(index, e.target.value)}
            />
          </FormGroup>
        ))}

        {/* 注目カード下ボタンのテキスト */}
        <FormGroup>
          <Label>注目カード下ボタンのテキスト</Label>
          <Input
            type="text"
            name="reccomend_text"
            value={article.reccomend_text}
            onChange={handleChange}
          />
        </FormGroup>

        {/* 注目カード下ボタンのURL */}
        <FormGroup>
          <Label>注目カード下ボタンのURL</Label>
          <Input
            type="text"
            name="reccomend_url"
            value={article.reccomend_url}
            onChange={handleChange}
          />
        </FormGroup>

        {/* 記事本文 */}
        <FormGroup>
          <Label>記事本文</Label>
          <TextArea
            ref={textareaRef}
            name="body"
            placeholder="記事を入力してください。"
            value={article.body}
            onChange={handleChange}
          />
        </FormGroup>

        {handlePreview({cardTitle: cardTitle, article: article})}

        {/* 作成ボタン */}
        <Button type="submit">作成</Button>
      </FormWrapper>

      {/* 右側のツールバー */}
      <ToolbarWrapper>
        <ToolbarHeading>Tool Bar</ToolbarHeading>
        {/* ツールボタンセクション */}
        <ToolButtons textarea={textareaRef.current} article={article} setArticle ={setArticle}/>

        {/* タグセクション */}
        <ArticleTags article={article} cardTitle={cardTitle} articleTags={articleTags} setArticle={setArticle}/>
      </ToolbarWrapper>
    </FormContainer>
  );
};

export default NewArticleForm;
