import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';
import { useLocation } from 'react-router-dom';

const formPartsConfig = [
  {
    labelText: 'ライター',
    key: 'name',
    type: 'text',
  },
]

const IndexAuthors = (props) => {
  const defaultQuery = useMemo(() => {
    return({
			limit: 40,
			sort: {
				key: 'created_at',
				order: 'DESC',
			},
			to_json_option: {
				methods: ['created_on', 'image_source'],
			}
    });
  })
  const [authors, setAuthors] = useState([]);
  const [query, setQuery] = useState(defaultQuery);
  const location = useLocation();

  useEffect(() => {
    api.httpGet(api.addQuery(`/admin/authors`, defaultQuery), (response) => {
      const records = response.body.map((record) => {
        record.image_source = {
          content: record.image_source,
          onClick: () => {
            const text = record.image_source.content;
            navigator.clipboard.writeText(text).then(e => {
              alert('コピーできました');
            });
          },
        }
        return(record);
      })
      setAuthors(records);
    });
  }, [location.key]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query}, event.target.name, event.target.value))
  }, [query, setQuery])

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    api.httpGet(api.addQuery(`/admin/authors`, query), (response) => {
      setAuthors(response.body);
    });
  }, [query, setAuthors])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
		api.deleteToDestroy(
			props,
			event,
			`/admin/authors/${id}`,
			'削除に成功しました。',
			`/authors`,
			'削除に失敗しました。',
			`/authors`,
		)
	}, [props])

  return (
    <IndexTemplate
      pageTitle={`ライター一覧`}
      tableHeaders={['id', 'ライター', 'image_key', 'アイコン', '作成日', '編集', '削除']}
      displayRecordKeys={['id', 'name', 'image_key', 'image_source', 'created_on']}
      records={authors}
      formPartsConfig={formPartsConfig}
      query={query}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexAuthors