import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue } from 'Modules/utility.js';

const formPartsConfig = [
  {
    labelText: '記事タグ名',
    key: 'name',
    type: 'text',
  },
]

const IndexArticleTags = (props) => {
	const cardTitle = useCardTitle();
  const [articleTags, setArticleTags] = useState([]);
  const [query, setQuery] = useState({
    limit: 40,
    page: 1,
    sort: {
      key: 'created_at',
      order: 'ASC',
    },
    to_json_option: {
      methods: ['created_on'],
    }
  });

  useEffect(() => {
    api.httpGet(api.addQuery(`/${cardTitle.name}/article_tags`, query), (response) => {
      setArticleTags(response.body);
    });
  }, [query,cardTitle.name]);

  const handleInputValue = useCallback((event) => {
    setQuery(setNestedJsonValue({...query, page: 1}, event.target.name, event.target.value))
  }, [query, setQuery])

  const deleteSubmit = useCallback((event, id) => {
		event.preventDefault();
    api.httpDelete(`/admin/${cardTitle.name}/article_tags/${id}`, (response) => {
      if (response.data.result) {
        const newArticleTags = [];
        const flashMessages = [];
        for (let i = 0; i < articleTags.length; i++) {
          if (articleTags[i].id !== id) {
            newArticleTags.push(articleTags[i]);
          } else {
            flashMessages.push(`id:${articleTags[i].id} ${articleTags[i].name}の削除に成功しました。`);
          }
        }
        localStorage.setItem('flashMessages', flashMessages);
        setArticleTags([...newArticleTags]);
      } else {
        localStorage.setItem('flashMessages', Object.keys(response.data.body).map(key=>response.data.body[key]));
        setArticleTags([...articleTags]);
      }
    });
	}, [cardTitle])

  return (
    <IndexTemplate
      pageTitle={`${cardTitle.name}:記事タグ一覧`}
      tableHeaders={['id', 'タグ名', '作成日', '編集', '削除']}
      displayRecordKeys={['id', 'name', 'created_on']}
      records={articleTags}
      formPartsConfig={formPartsConfig}
      query={query}
      setQuery={setQuery}
      handleInputValue={handleInputValue}
      deleteSubmit={deleteSubmit}
    />
  );
}

export default IndexArticleTags