import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import api from 'Modules/api.js';
import IndexTemplate from 'Components/Templates/Common/DefaultIndexTemplate';
import useCardTitle from 'Modules/useCardTitle';
import { setNestedJsonValue, getNestedJsonValue, getParsedQuery, addQuery } from 'Modules/utility.js';
import Anchor from 'Components/Atoms/Anchor';
import { useLocation } from 'react-router-dom';

const formPartsConfig = [
  {
    labelText: 'おちゃid',
    key: 'id',
    type: 'number',
  },
  {
    labelText: 'カード名',
    key: 'card_name',
    type: 'text',
  },
  {
    labelText: 'おちゃ商品名',
    key: 'name',
    type: 'text',
  },
  {
    labelText: 'おちゃパックコード',
    key: 'pack_code',
    type: 'text',
  },
  {
    labelText: '・紐付け状態絞り込み',
    key: 'relation_status',
    type: 'checkboxList',
    list: [
      {key: 'result', value: false},
      {key: 'result', value: '未'},
      {key: 'result', value: true},
    ],
    displayOptions: ['紐付け失敗商品', '紐付け未処理商品', '紐付け出来てる商品'],
  },
  {
    labelText: 'おちゃ最小ID',
    key: 'id.min',
    type: 'number',
  },
  {
    labelText: '無視済は表示',
    key: 'has_ignored',
    type: 'radioCombi',
    options: ['しない', 'する'],
    optionValues: ['false', 'true'],
  },
]

const IndexOchaProducts = (props) => {
	const cardTitle = useCardTitle();
  const [ochaProducts, setOchaProducts] = useState([]);
  const [query, setQuery] = useState(null);
  const location = useLocation();

  useEffect(() => {
    api.httpGet(api.addQuery(`/admin/${cardTitle.name}/card_conditions`, {priority: {min: 1, max: 998}}), (response) => {
      const defaultQuery = {
        id: {
          min: cardTitle.words.max_id_of_old_products,
        },
        limit: 100,
        condition_name: response.body.map((condition) => {return(condition.name)}),
        page: 1,
        relation_status: [
          {key: 'result', value: false},
        ],
        is_card_product: true,
        sort: {
          key: 'id',
          order: 'DESC',
        },
        has_ignored: false,
        to_json_option: {
          methods: ['relation_error_messages']
        }
      }
      setQuery({...defaultQuery, ...location.query});
    })
  }, [cardTitle.name])

  useEffect(() => {
    if (!query) {
      setOchaProducts([]);
      return null;
    }
    api.httpGet(api.addQuery(`/admin/${cardTitle.name}/ocha_products`, query), (response) => {
      const records = [...response.body];
      for (let i = 0; records.length > i; i++) {
        records[i].productName = getNestedJsonValue(records[i], 'name')
        records[i] = setNestedJsonValue({...records[i]}, 'name', () => {
          return(<Anchor content={records[i].productName} href={`${cardTitle.words.ecUrl}/product/${records[i].id}`}/>);
        });
      }
      setOchaProducts(records);
    });
  }, [query, cardTitle.name])

  const handleInputValue = (event) => {
    setQuery(setNestedJsonValue({...query, page: 1}, event.target.name, event.target.value));
  }

  if (!query) {
    return null;
  }

  return (
    <IndexTemplate
      className={props.className}
      pageTitle={`${cardTitle.name}:おちゃ商品一覧`}
      tableHeaders={[
        '商品番号', 'おちゃ商品名', 'おちゃのカテゴリ',
        '価格', '在庫', 'カード名', 'レアリティ名', 'カード型番', 'パックコード', '状態名',
        '特記', '紐付けエラー', '無視フラグ', '編集'
      ]}
      displayRecordKeys={[
        'id', 'name', 'category',
        'price', 'stock_count', 'card_name', 'rarity_name', 'card_model_number', 'pack_code', 'condition_name',
        'extra_difference', 'relation_error_messages', 'has_ignored'
      ]}
      records={ochaProducts}
      formPartsConfig={formPartsConfig}
      query={query}
      handleInputValue={handleInputValue}
      setQuery={setQuery}
    />
  );
}

const StyledIndexOchaProducts = styled(IndexOchaProducts)`
div.action-buttons {
  display: none;
}
`;

export default StyledIndexOchaProducts;